// 还款登记
<template>
  <div>
    <!-- 查询 -->
    <base-form
      :componentList="formConfig"
      :formAttrs="{
        model: queryParas,
        labelWidth: '90px',
      }"
      class="formStyle"
      @handleFilter="handleFilter"
      @clearParams="clearParams"
      ref="formData"
    >
    </base-form>
    <!-- 表格 -->
    <base-table
      class="main-page-table"
      :columns="columns"
      :showPage="true"
      :tableAttrs="{
        data: tableData,
        stripe: true,
      }"
      ref="tableData"
      :api="api"
      :getApi="'getPage'"
      :webPage="false"
      :queryParas.sync="queryParas"
      :loadCount.sync="loadCount"
      :dataSource.sync="tableData"
    >
      <template slot="index" slot-scope="scope">
        {{ scope.$index + 1 }}
      </template>
      <!-- 还款状态颜色 -->
      <template slot="repayStatus" slot-scope="scope">
        <span :class="{red:scope.row.repayStatus===90}">{{getRepayStatus(scope.row)}}</span>
      </template>
      <!-- 按钮组 -->
      <template slot="action" slot-scope="scope">
        <icon-button
          @click.prevent="goNextPage('/repaymentplaninfo', { type: 'HKDJ', loanId:scope.row.loanId,name:'还款登记',edit:'look'}, scope.row)"
          content="查看"
          icon="iconfont iconchakan"
        ></icon-button>
        <icon-button
          @click.prevent="goNextPage('/repaymentplaninfo', { type: 'HKDJ',loanId:scope.row.loanId,name:'还款登记',edit:'dj' }, scope.row)"
          content="登记"
          icon="iconfont icondengji"
          v-if="scope.row.repayStatus!==30"
        ></icon-button>
      </template>
    </base-table>
  </div>
</template>
<script>
import IconButton from '@/components/common/button/icon-button/icon-button'
import BaseForm from '@/components/common/base-form/base-form.vue'
import { dataForm, dataTable } from './config'
import BaseTable from '@/components/common/table/base-table/base-table.vue'
import { repayregistApi } from '@/api/businessApi'
import { getDictNameMaps } from '@/filters/fromDict'
export default {
  name: 'paymentRegistration',
  components: {
    BaseForm,
    BaseTable,
    IconButton
  },
  data () {
    return {
      queryParas: {
        pageSize: 10,
        pageIndex: 1
      },
      loadCount: 0,
      tableData: [],
      supplierList: []
    }
  },
  computed: {
    formConfig () {
      return dataForm(this)
    },
    columns () {
      return dataTable(this)
    },
    api () {
      return repayregistApi
    }
  },
  mounted () {
    this.handleFilter()
    this.getDropList()
  },
  methods: {
    getRepayStatus (row) {
      const data = getDictNameMaps('REPAY_STATUS')
      if (data) {
        for (const [key, value] of Object.entries(data)) {
          if (parseInt(row.repayStatus) === parseInt(value)) {
            return key
          }
        }
      }
    },
    /**
     * 查询
     */
    handleFilter () {
      this.queryParas.pageIndex = 1
      this.loadCount++
    },
    /**
     * 重置
     */
    clearParams () {
      this.queryParas = {
        pageSize: 10,
        pageIndex: 1
      }
      this.$refs.formData.resetFields()
    },
    //  跳转方法
    goNextPage (url, data) {
      this.$router.push({
        path: url,
        query: data
      })
    },
    /**
     * 获取供应商
     */
    getDropList () {
      this.api.getDrop()
        .then(res => {
          if (res.data) {
            this.supplierList = res.data
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.red{
  color:#EE1C1C !important;
}
.paymentRegistration {
  padding: 20px;
  height: 100%;
}
</style>
