import BaseSelect from '@/components/common/base-select/base-select.vue'
import { getDictLists, getDictNameMaps } from '@/filters/fromDict'
// import { validbusinessNo } from '@/utils/validate'
// 查询form
export const dataForm = (content) => {
  return [
    {
      label: '业务编号',
      prop: 'businessNo',
      // rules: [{ required: false, validator: validbusinessNo, trigger: 'blur' }],
      attrs: {
        placeholder: '请输入',
        maxlength: 20
      }
    },
    {
      label: '供应商',
      prop: 'gysId',
      rules: [{ required: false, message: '请输入', trigger: 'blur' }],
      attrs: {
        options: content.supplierList,
        selectedField: ['keyId', 'enterpriseName'],
        placeholder: '请输入',
        filterable: true
      },
      on: {
        change: (data) => {
          const [obj] = content.supplierList.filter(item => item.keyId === data)
          content.queryParas.gysCompanyName = obj ? obj.enterpriseName : ''
        }
      },
      tag: BaseSelect
    },
    {
      label: '还款状态',
      prop: 'repayStatus',
      attrs: {
        placeholder: '请选择',
        options: getDictLists('REPAY_STATUS'),
        selectedField: ['dictId', 'dictName']
      },
      tag: BaseSelect
    }
  ]
}

// 表格form
export const dataTable = (conten) => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '80px'
    },
    {
      label: '业务编号',
      prop: 'businessNo',
      minWidth: '150px',
      'show-overflow-tooltip': true
    },
    {
      label: '供应商',
      prop: 'gysCompanyName',
      minWidth: '150px',
      'show-overflow-tooltip': true
    },
    {
      label: '放款金额(元)',
      prop: 'loanAmount',
      minWidth: '150px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.loanAmount ? row.loanAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '预计还款金额(元)',
      prop: 'preRepayAmount',
      minWidth: '150px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.preRepayAmount ? row.preRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : '')
      }
    },
    {
      label: '已还金额(元)',
      prop: 'actualRepayAmount',
      minWidth: '150px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.actualRepayAmount ? row.actualRepayAmount.toLocaleString('en-US', { minimumFractionDigits: 2 }) : row.actualRepayAmount)
      }

    },
    {
      label: '待还金额(元)',
      prop: 'notGive',
      minWidth: '150px',
      'show-overflow-tooltip': true,
      formatter: row => {
        return (row.notGive ? row.notGive.toLocaleString('en-US', { minimumFractionDigits: 2 }) : row.notGive)
      }
    },
    {
      label: '还款状态',
      prop: 'repayStatus',
      minWidth: '150px',
      formatter: row => {
        const data = getDictNameMaps('REPAY_STATUS')
        if (data) {
          for (const [key, value] of Object.entries(data)) {
            if (parseInt(row.repayStatus) === parseInt(value)) {
              return key
            }
          }
        }
      }
    },
    {
      label: '操作',
      prop: 'action',
      width: '155px',
      fixed: 'right'
    }
  ]
}
